import React from "react";
import "./assets/App.css";
import AppBarHeader from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AttributionsScreen from "./pages/attributions/AttributionsScreen";
import PrivacyPolicyScreen from "./pages/privacy_policy/PrivacyPolicyScreen";
import CookiePolicyScreen from "./pages/cookie_policy/CookiePolicyScreen";
import ContactScreen from "./pages/contact_us/ContactScreen";
import TermsScreen from "./pages/terms/TermsScreen";
import AboutUsScreen from "./pages/about_us/AboutUsScreen";
import FAQ from "./pages/FAQ";
import ScrollToTop from "./ScrollToTop";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("SiteTitle")}</title>
        <meta name="AppDescription" content={t("AppDescription")} />
      </Helmet>
      <Router>
        <AppBarHeader />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/attributions" element={<AttributionsScreen />} />
          <Route path="/privacy_policy" element={<PrivacyPolicyScreen />} />
          <Route path="/cookie_policy" element={<CookiePolicyScreen />} />
          <Route path="/contact" element={<ContactScreen />} />
          <Route path="/terms" element={<TermsScreen />} />
          <Route
            path="/aboutus/:isReasonExpanded?"
            element={<AboutUsScreen />}
          />
          <Route
            path="/faq/group_creation"
            element={<FAQ category={"groupCreation"} />}
          />
          <Route
            path="/faq/hot_create"
            element={<FAQ category={"hotCreate"} />}
          />
          <Route path="/faq/interact" element={<FAQ category={"interact"} />} />
          <Route
            path="/faq/events_more"
            element={<FAQ category={"eventsMore"} />}
          />
          <Route
            path="/faq/event_creation"
            element={<FAQ category={"eventCreation"} />}
          />
          <Route
            path="/faq/group_page"
            element={<FAQ category={"groupPage"} />}
          />
          <Route
            path="/faq/event_page"
            element={<FAQ category={"eventPage"} />}
          />
          <Route path="/faq/profile" element={<FAQ category={"profile"} />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
