const es = {
  Slogan: "Real Socializing",
  AvailableIn: "Disponible en",
  AvailableNow: "Disponible ahora",
  Days: "DÍAS",
  Hours: "HRS",
  Minutes: "MIN",
  Seconds: "SEC",
  Features: "Características",
  JoinGroupsOfHeader: "ÚNETE A GRUPOS DE PERSONAS INTERESANTES",
  JoinGroupsOfTextOne: "Conocer gente nunca fue tan fácil.",
  JoinGroupsOfTextTwo:
    "Ve grupos de personas interesantes que se reunirán en un lugar específico, un gran evento o un evento creado por usuarios. Pide unirte a ellos o dale me gusta para que puedan responder invitándote.",
  FriendsAndDatesHeader: "AMIGOS Y CITES QUE SON ADECUADOS PARA TI",
  FriendsAndDatesTextOne: "Salir o socializar puramente, es tu elección.",
  FriendsAndDatesTextTwo:
    "No te pierdas conocer a personas que coincidan con tu tipo. Las personas con rasgos y preferencias de citas similares aparecerán como una Coincidencia Social. ¡Nos encanta conectarte con las personas adecuadas para ti!",
  CreateUniqueEventsHeader: "CREA TUS PROPIOS GRUPOS Y EVENTOS ÚNICOS",
  CreateUniqueEventsTextOne:
    "¿Te apetece organizar una fiesta o quizás jugar al fútbol con tus amigos?",
  CreateUniqueEventsTextTwo:
    "Además de crear y unirte a grupos en lugares y grandes eventos que tenemos listados, también puedes crear tus propios eventos personalizados. Hazlo masivo o mantenlo privado, la elección es tuya.",
  Legal: "Legal",
  PrivacyPolicy: "Política de Privacidad",
  Terms: "Términos",
  CookiePolicy: "Política de Cookies",
  ContactUs: "Contáctanos",
  Download: "¡Descargar!",
  AboutUs: "Sobre Nosotros",
  RealSocializing: "Socialización Real",
  WhyToGetJoinHeader: "¿Por qué obtener Join?",
  WhyToGetJoinTextOne:
    "Dudamos que estés usando siquiera una aplicación que promueva realmente tu vida social. \nSin ofender a grandes jugadores como Instagram o Facebook: Hasta la fecha hay pocas plataformas “sociales” exitosas, pero en realidad, algo así como plataformas que lo hacen todo, y por una buena razón. Pero ofrecemos una dimensión a la socialización que faltaba en las aplicaciones sociales existentes hasta hace poco.",
  WhyToGetJoinTextTwo:
    "Afirmamos con orgullo ser la primera aplicación social real, porque solo hacemos una cosa y la hacemos lo mejor posible: Unimos a las personas en la vida real.\n\nUsar Join significa tener acceso a grupos de personas que han programado visitar negocios de hospitalidad y ocio, como bares, discotecas, cafeterías, eventos musicales y más. También están disponibles fiestas, partidos deportivos y otros eventos a los que puedes unirte.\nAdemás de ayudarte a planificar cosas divertidas con tus amigos actuales, también hemos hecho de ofrecerte la oportunidad de hacer nuevos amigos y citas de calidad nuestras principales prioridades. Olvídate de sentirte incómodo, ya que ahora puedes conocer cómodamente a las personas adecuadas con el mínimo esfuerzo. No podemos explicar realmente cómo en unas pocas frases, pero nos aseguramos de romper el hielo de una vez por todas. Join está lejos de ser otra plataforma de citas incómoda que la gente se avergüenza de usar.\n\nQuerido usuario de la aplicación social, aunque Join es el soplo de aire fresco que necesitabas, también te necesitamos absolutamente a ti. Esperamos que disfrutes tu experiencia en nuestra aplicación.",
  OurHistoryHeader: "Nuestra Historia - Join & El Equipo",
  OurHistoryTextOne:
    "Covid-19 dio la vuelta al mundo. Lo que la cuarentena hizo a Grecia, en términos sociales, fue que eliminó la socialización de las vidas de las personas y destruyó su hogar, la Industria de la Hospitalidad. Ahí es donde entra Join.",
  OurHistoryTextTwo:
    "La verdad es que nuestras relaciones humanas no eran las mismas después de la pandemia. Algunas salieron más débiles y algunas más fuertes, mientras que bastantes fueron abandonadas por completo\n\n-La gente distinguió quién realmente importa para ellos-\n\nITALIC“Dicho esto, en una aburrida noche de cuarentena en 2021, decidí conectar de alguna manera los bares, clubes y cafeterías que se habían echado de menos, por nombrar solo algunos, con la necesidad de las personas de hacer relaciones reales. El resultado es hoy llamado Join, una aplicación móvil disponible en App Store y Google Play y soy Dimitris Chatzichristofas, su cofundador.\n\nITALICCrear Join no fue un proceso fácil y por eso estoy agradecido de haber tenido a dos grandes desarrolladores y cofundadores, George Bratsiotis y Chris, quienes desarrollaron la aplicación desde cero mientras yo me encargaba de la mayoría de las tareas comerciales. Aún tenemos mucho trabajo por hacer y estamos emocionados por lo que está por venir.“",
  FullName: "Nombre Completo",
  Subject: "Asunto",
  AccountOrPrivacyIssue: "Problema de Cuenta / Privacidad",
  ReportInappropriateBehaviour: "Informar Comportamiento Inapropiado",
  UserComplaint: "Queja del Usuario",
  BusinessEnquiry: "Consulta de Negocios",
  Other: "Otro",
  Message: "Mensaje",
  SubmitButton: "Enviar",
  INeedHelpCreatingMyGroupQ: "Necesito ayuda para crear mi grupo.",
  INeedHelpCreatingMyGroupQASectionOne:
    "Primero, debes saber que ser el creador de tu propio grupo significa que tienes control absoluto sobre él, así que no hay necesidad de estresarse, incluso si es el primero que estás creando. Aquí tienes una guía simple.",
  DateAndTimeDescrGroups:
    "Aquí estás eligiendo la fecha y hora de reunión de tu grupo. No te confundas con las horas a.m.: Por ejemplo, domingo, 00:45 puede seguir siendo sábado por la noche en tu mente, pero en Join es domingo por la mañana y así es como debes listarlo.",
  InviteYourFriendsDescrGroups:
    "Este es el paso en el que inicialmente invitas a tus amigos (no te preocupes, puedes invitar más después). Los miembros invitados recibirán una notificación de invitación de tu parte, la cual deberán aceptar afirmativamente si desean unirse al grupo. Puedes crear un grupo sin invitar a nadie y permitir que las personas soliciten unirse a ti.",
  MakeAStatement: "Haz una Declaración",
  MakeAStatementDescrGroups:
    "Esta es tu oportunidad de añadir un toque personal a tu grupo. Esto puede ser algo como “Estamos celebrando mi cumpleaños” o “Chicas, vistan un vestido blanco”.",
  YourGroupIsReadyForCreation: "¡Tu grupo está listo para ser creado!",
  INeedHelpCreatingMyEvent: "Necesito ayuda para crear mi evento.",
  INeedHelpCreatingMyEventASectionOne:
    "Primero, debes saber que ser el creador de tu propio evento significa que tienes control absoluto sobre él, así que no hay necesidad de estresarse, incluso si es el primero que estás creando. Aquí tienes una guía simple.",
  EventTitle: "Título del Evento",
  EventTitleDescrEvents:
    "Esto debe ser una breve descripción de tu evento. Algo como “Fútbol 5x5 en Chalandri”, “Fiesta en playa de ubicación secreta” o “Encuentro relajado en la plaza de Agia Paraskevi”.",
  DescribeYourEvent: "Describe tu Evento",
  DescribeYourEventDescrEvents:
    "Ahora es tu oportunidad de describir tu evento. Incluye cualquier detalle que te hubiera gustado ver en un evento como el tuyo, creado por otra persona. Cualquier cosa importante, debes incluirla aquí. Créenos, si eres analítico, entonces más personas y personas más adecuadas mostrarán interés en tu evento.",
  PrivateEvent: "Evento Privado",
  PrivateEventDescrEvents:
    "Todos los eventos personalizados son públicos por defecto, lo que significa que todos los usuarios de Join pueden ver e interactuar con tu evento, excepto las personas que hayas bloqueado. Si cambias tu evento a privado, el acceso se limitará solo a tus amigos y a las personas que invites. Puedes alterar la privacidad de tu evento incluso después de su creación.",
  DateAndTime: "Fecha y Hora",
  DateAndTimeDescrEvents:
    "Aquí estás eligiendo la fecha y la hora en que comenzará tu evento. No te confundas con las horas a.m.: Por ejemplo, domingo, 00:45 puede seguir siendo sábado por la noche en tu mente, pero en Join es domingo por la mañana y así es como debes listarlo.",
  Category: "Categoría",
  CategoryDescrEvents:
    "Concluimos que tu evento probablemente se encuadrará en una de estas categorías: Deportes, Fiesta, Viaje o Encuentro. Si no puedes ver claramente en cuál de estas categorías encaja tu evento, elige “encuentro”. Después de todo, un evento no puede no ser un encuentro.",
  InviteYourFriends: "Invita a tus Amigos",
  InviteYourFriendsDescrEvents:
    "Este es el paso en el que inicialmente invitas a tus amigos (no te preocupes, puedes invitar más después). Los miembros invitados recibirán una notificación de invitación de tu parte, la cual deberán aceptar afirmativamente si desean unirse al evento. Puedes crear un evento sin invitar a nadie y permitir que las personas soliciten unirse a ti.",
  YourCustomEventIsReadyForCreation: "¡Tu evento está listo para ser creado!",
  CanICreateAPrivateGroupOrJoinAGroupPrivatelyQ:
    "¿Puedo crear un grupo privado o unirme a un grupo de forma privada?",
  CanICreateAPrivateGroupOrJoinAGroupPrivatelyA:
    "Todos los grupos en Lugares y Eventos Verificados son públicos. A pesar de eso, puedes cambiar tu perfil a privado, lo que limita tu apariencia en los grupos solo a tus amigos y a los miembros que ya están en ese grupo específico.",
  CanICreateAPrivateEventOrJoinAnEventPrivatelyQ:
    "¿Puedo crear un evento privado o unirme a un evento de forma privada?",
  CanICreateAPrivateEventOrJoinAnEventPrivatelyA:
    "A diferencia de los grupos en Lugares y Eventos Verificados, puedes crear o unirte a Eventos Personalizados privados. Si eres el creador de un evento personalizado, puedes cambiar tu evento de abierto a privado y viceversa en cualquier momento.",
  AmIMakingAreservationWhenCreatingAGroupQ:
    "¿Estoy haciendo una reserva al crear un grupo?",
  AmIMakingAreservationWhenCreatingAGroupA:
    "En este momento, Join no realiza reservas de mesas para tus grupos.",
  IAmHesitantToJoinQ:
    "Tengo dudas sobre unirme y crear grupos en Join, ya que otras personas conocerán mi vida social en la vida real.",
  IAmHesitantToJoinA:
    "Entendemos profundamente tu preocupación relacionada con tu privacidad y hacemos todo lo posible por satisfacer tus necesidades de privacidad, como ofrecerte la capacidad de restringir la exposición de tu perfil a través de la configuración de la aplicación o crear eventos privados, mientras mantenemos a Join funcional, siendo una aplicación que tiene como objetivo unir a las personas en la vida real.",
  CanIInviteFriendsToSomeoneElsesEventQ:
    "¿Puedo invitar amigos al evento de otra persona?",
  CanIInviteFriendsToSomeoneElsesEventA:
    "Sí, puedes. Solo si es un evento privado, su creador recibirá una notificación de tu intención de invitar a alguien a su evento y deberá aprobarlo para que la invitación se haga efectiva. En eventos abiertos, al igual que en los grupos, cualquier miembro unido puede invitar libremente a sus amigos sin necesidad de involucrar al creador.",
  IHaveBlockedSomeoneOnJoinQ:
    "He bloqueado a alguien en Join. ¿Pueden ver los grupos y eventos a los que me uno?",
  IHaveBlockedSomeoneOnJoinA:
    "Las personas que has bloqueado en Join no pueden ver si te has unido, creado o recibido una invitación a un grupo o evento, a menos que también estén relacionadas con ese grupo o evento.",
  WhatAmISeeingInTheHotPageQ: "¿Qué estoy viendo en la página de “Hot”?",
  WhatAmISeeingInTheHotPageA:
    "El contenido de Join está compuesto por Grupos y Eventos Personalizados. Los grupos pueden crearse en “Lugares” o en “Eventos Verificados”, mientras que los Eventos Personalizados son entidades independientes creadas y personalizadas por los usuarios. Hemos creado un algoritmo que filtra grupos y eventos personalizados según cuán bien tu perfil coincide con los perfiles en ellos, mezcla y presenta esos resultados en la página de Hot.",
  WhyIsJoinAskingForAllThisProfileInfoQ:
    "¿Por qué Join está pidiendo toda esta información de perfil?",
  WhyIsJoinAskingForAllThisProfileInfoA:
    "Respetamos tu preocupación respecto a la privacidad de tu perfil, mientras nos esforzamos por cumplir nuestro único propósito para nuestros usuarios. Ese propósito es unir a las personas en la vida real. Para lograrlo correctamente, necesitamos que nos cuentes sobre ti, lo que te gusta hacer en la vida real, dónde estudiaste o has estudiado, si eres estudiante y qué tipo de personas prefieres interactuar.",
  CanIOmitToDiscloseSomeProfileInfoAskedByJoinQ:
    "¿Puedo omitir revelar alguna información del perfil solicitada por Join?",
  CanIOmitToDiscloseSomeProfileInfoAskedByJoinA:
    "Generalmente, aconsejamos a nuestros usuarios no omitir la correcta y adecuada presentación de sí mismos a través de sus perfiles en Join, ya que esto perjudicará su experiencia en nuestra plataforma. A pesar de eso, en muchos casos, puedes elegir “Ninguno” u “Otro” como respuesta, en caso de que no quieras revelar la información que te estamos solicitando.",
  WhatISProfileStrengthQ: "¿Qué es la “Fortaleza del Perfil”?",
  WhatISProfileStrengthA:
    "La Fortaleza del Perfil muestra cuán adecuado y fuerte será tu perfil en Join, basado en su estado actual. No indica si está completo o no y solo es visible para ti.",
  DiscoverTheMainIdea: "Descubre la idea",
  SeeHowToInteract: "Ve cómo interactuar",
  MoreAboutEventCreation: "Más sobre eventos",
  ContactUsTitle: "Contáctanos",
  ContactUsSubtitle:
    "Póngase en contacto para informar posibles problemas, para consultas comerciales o simplemente para saludar.",
  SomethingAboutUs: "Algo sobre nosotros",
  SiteTitle: "Join | Grupos, Eventos & Personas",
  AppDescription:
    "Join es una aplicación móvil que se centra en la socialización real. Úsala para unirte a grupos y eventos como fiestas y mucho más. Crea una cuenta y conoce personas de una manera nunca antes vista.",
  OpenInApp: "Abrir en la App",
  FaqId1Q: "Necesito ayuda para crear mi grupo",
  FaqId1A:
    'Primero, debes saber que ser el creador de tu grupo significa que tienes control absoluto sobre él, así que no hay necesidad de estresarse, incluso si es el primero que creas. Aquí tienes una guía sencilla.\n\n**Fecha y Hora**\n\nAquí debes elegir la fecha y la hora de reunión de tu grupo. No te confundas con los horarios a.m.: por ejemplo, el domingo a las 00:45 puede seguir pareciendo sábado por la noche para ti, pero en Join es la madrugada del domingo y así es como debes registrarlo.\n\n**Invita a tus Amigos**\n\nEn este paso, invitas inicialmente a tus amigos (no te preocupes, podrás invitar a más personas más adelante). Los miembros invitados recibirán una notificación de invitación de tu parte, a la cual deberán responder afirmativamente para unirse al grupo. También puedes crear un grupo sin invitar a nadie y permitir que las personas soliciten unirse.\n\n**Haz una Declaración**\n\nEsta es tu oportunidad para agregar un toque personal a tu grupo. Puede ser algo como "Estamos celebrando mi cumpleaños" o "Las chicas deben usar un vestido blanco".\n\n¡Tu grupo ya está listo para ser creado!',

  FaqId2Q: "Necesito ayuda para crear mi evento",
  FaqId2A:
    'Primero, debes saber que ser el creador de tu propio evento significa que tienes control absoluto sobre él, así que no hay necesidad de estresarse, incluso si es el primero que creas. Aquí tienes una guía sencilla.\n\n**Título del Evento**\n\nDebe ser una breve descripción de tu evento. Algo como "Fútbol 5x5 en Chalandri", "Fiesta en la playa en un lugar secreto" o "Encuentro tranquilo en la plaza de Agia Paraskevi".\n\n**Describe tu Evento**\n\nAhora tienes la oportunidad de describir tu evento. Incluye cualquier detalle que te gustaría ver en un evento similar creado por otra persona. Cualquier información importante debe incluirse aquí. Créenos: cuanto más detallado seas, más personas se unirán y más adecuadas serán las personas que participen.',

  FaqId3Q: "¿Puedo crear un grupo privado o unirme a uno de forma privada?",
  FaqId3A:
    "Todos los grupos en Lugares y Grandes Eventos son públicos. Sin embargo, puedes cambiar tu perfil a privado, lo que limitará tu visibilidad en los grupos solo a tus amigos y a los miembros del grupo en el que te has unido.",

  FaqId4Q: "¿Puedo crear un evento privado o unirme a uno de forma privada?",
  FaqId4A:
    "A diferencia de los grupos en Lugares y Grandes Eventos, puedes crear o unirte a Eventos de Usuario privados. Si eres el creador de un evento personalizado, puedes cambiar su estado entre abierto y privado en cualquier momento.",

  FaqId5Q: "¿Estoy haciendo una reserva al crear un grupo?",
  FaqId5A: "Por el momento, Join no realiza reservas de mesas para los grupos.",

  FaqId6Q:
    "Me da inseguridad crear o unirme a grupos en Join, porque otras personas conocerán mi vida social real",
  FaqId6A:
    "Entendemos perfectamente tu preocupación por la privacidad y hacemos todo lo posible por satisfacer tus necesidades, como permitirte restringir la visibilidad de tu perfil a través de la configuración de la app o crear eventos privados, manteniendo la funcionalidad de Join, cuya esencia es reunir a las personas en la vida real.",

  FaqId7Q: "¿Puedo invitar amigos al evento de otra persona?",
  FaqId7A:
    "Sí, puedes. Sin embargo, si el evento es privado, el creador recibirá una notificación de tu intención de invitar a alguien y deberá aprobarla para que la invitación sea efectiva. En eventos abiertos, al igual que en los grupos, cualquier miembro puede invitar libremente a sus amigos sin necesidad de la aprobación del creador.",

  FaqId8Q:
    "He bloqueado a alguien en Join. ¿Puede ver los grupos y eventos en los que participo?",
  FaqId8A:
    "Las personas que has bloqueado en Join no pueden ver si te has unido, has creado o has sido invitado a un grupo o evento, a menos que ellos también estén relacionados con ese grupo o evento.",

  FaqId9Q: "¿Qué estoy viendo en la 'Hot Page'?",
  FaqId9A:
    "El contenido de Join está compuesto por Grupos y Eventos de Usuario. Los grupos pueden ser creados bajo **Lugares** o **Eventos Verificados**, mientras que los Eventos de Usuario son entidades independientes creadas y personalizadas por los propios usuarios. Hemos desarrollado un algoritmo que filtra grupos y eventos personalizados según la compatibilidad con tu perfil y los muestra en la Hot Page.",

  FaqId10Q: "¿Por qué Join solicita tanta información de mi perfil?",
  FaqId10A:
    "Respetamos tu preocupación por la privacidad, pero nuestro objetivo principal es reunir a las personas en la vida real. Para lograr esto de manera efectiva, necesitamos conocer más sobre ti: qué te gusta hacer, dónde estudias o has estudiado (si eres estudiante) y qué tipo de personas prefieres conocer.",

  FaqId11Q:
    "¿Puedo omitir parte de la información del perfil que solicita Join?",
  FaqId11A:
    "Aconsejamos a nuestros usuarios que completen correctamente su perfil en Join para mejorar su experiencia en la plataforma. Sin embargo, en muchas ocasiones, puedes seleccionar 'Ninguno' u 'Otro' como respuesta si prefieres no compartir cierta información.",

  FaqId12Q: "¿Qué es la 'Puntuación de Perfil'?",
  FaqId12A:
    "La Puntuación de Perfil indica qué tan adecuado y sólido es tu perfil en Join, según su estado actual. No indica si está completo o no y solo es visible para ti.",

  FaqId13Q: "¿Cuáles son estas dos opciones?",
  FaqId13A:
    "En esta etapa, verás dos opciones.\n\nLa primera es crear tu propio **Event Usuario**. Haz clic en el botón -Event Usuario- y crea tu propio evento, ya sea una fiesta, un evento deportivo, un encuentro o incluso un viaje.\n\nLa segunda opción te permite elegir un Tienda o un Gran-Event para crear tu **Grupo**. Elige esta opción si la ubicación deseada es una Tienda o un Gran-Event que ya está en Join.",

  FaqId14Q: "¿Cuál es la diferencia entre un 'Evento de Usuario' y un 'Grupo'?",
  FaqId14A:
    "Un **Event Usuario** es un evento creado por otro miembro de Join como tú, a diferencia de los **Gran-Event**, que son similares a las **Tiendas**, ya que son subidos por el equipo de Join.\n\nLos **Event Usuario** tienen su propia identidad y presencia, mientras que los **Grupos** solo aparecen bajo una Tienda o un Gran-Event.",
};

export default es;
