const zh = {
  Slogan: "Real Socializing",
  AvailableIn: "可用语言",
  AvailableNow: "现在可用",
  Days: "天",
  Hours: "小时",
  Minutes: "分钟",
  Seconds: "秒",
  Features: "功能",
  JoinGroupsOfHeader: "加入有趣的群体",
  JoinGroupsOfTextOne: "认识新朋友从未如此简单。",
  JoinGroupsOfTextTwo:
    "查看将会在某个地方、大型活动或用户创建的活动中见面的有趣群体。请求加入他们或点赞，以便他们可以回应并邀请您。",
  FriendsAndDatesHeader: "适合您的朋友和约会对象",
  FriendsAndDatesTextOne: "约会还是纯粹社交，由您决定。",
  FriendsAndDatesTextTwo:
    "不要错过认识符合您类型的人的机会。具有匹配特征和约会偏好的人会显示为社交匹配。我们喜欢将您与适合您的正确人选联系起来！",
  CreateUniqueEventsHeader: "创建您自己的群体和独特活动",
  CreateUniqueEventsTextOne: "想举办派对还是和朋友一起踢足球？",
  CreateUniqueEventsTextTwo:
    "除了创建和加入我们列出的地方和大型活动中的群体，您还可以创建自己的定制活动。可以让它变得盛大，也可以保持私密，由您决定。",
  Legal: "法律",
  PrivacyPolicy: "隐私政策",
  Terms: "条款",
  CookiePolicy: "Cookie政策",
  ContactUs: "联系我们",
  Download: "下载！",
  AboutUs: "关于我们",
  RealSocializing: "真正的社交",
  WhyToGetJoinHeader: "为什么要使用Join？",
  WhyToGetJoinTextOne:
    "我们怀疑您是否使用了真正促进社交生活的应用程序。 对像Instagram或Facebook这样的大玩家没有冒犯：到目前为止，成功的“社交”应用程序很少——但实际上，它们往往是全能平台，这也是有原因的。但我们提供了一种社交维度，这是直到最近的社交应用程序所缺失的。",
  WhyToGetJoinTextTwo:
    "我们自豪地宣称自己是第一个真正的社交应用程序，因为我们只做这一件事，并尽最大努力做到最好：我们将人们带到现实生活中。使用Join意味着可以访问那些计划访问酒吧、夜总会、咖啡厅、音乐活动等款待和休闲业务的群体。还可以参加派对、体育比赛和其他活动。除了帮助您与现有朋友计划有趣的活动外，我们还将提供机会结交新的优质朋友和约会对象作为我们的首要任务。不要担心感到尴尬，因为您现在可以轻松地与合适的人见面，付出最少的努力。我们不能在几句话中完全解释清楚，但我们确保彻底打破了僵局。Join远不是另一个让人尴尬的约会平台。",
  OurHistoryHeader: "我们的历史 - Join & 团队",
  OurHistoryTextOne:
    "Covid-19使世界颠倒过来。隔离对希腊的社会方面的影响是，它从人们的生活中移除了社交，并摧毁了它的家园——款待行业。这就是Join发挥作用的地方。",
  OurHistoryTextTwo:
    "事实是，疫情后我们的关系并没有像以前那样。有人变得更弱，有人变得更强，而有些人则完全被遗弃。\n\n- 人们区分了谁对他们真正重要 - \n\nITALIC “也就是说，在2021年的一个无聊的隔离晚上，我决定以某种方式将当时错过的酒吧、俱乐部和咖啡厅等与人们建立真实关系的需求联系起来。结果就是今天称为Join的移动应用程序，它可以在App Store和Google Play上获得，我是Dimitris Chatzichristofas，Join的联合创始人。\n\nITALIC 创建Join并不是一个顺利的过程，这就是为什么我很感激有两个优秀的开发人员和联合创始人，George Bratsiotis和Chris，他们从零开始开发了这个应用程序，而我则负责大部分业务任务。我们仍然有很多工作要做，对未来充满期待。“",
  FullName: "全名",
  Subject: "主题",
  AccountOrPrivacyIssue: "账户/隐私问题",
  ReportInappropriateBehaviour: "举报不当行为",
  UserComplaint: "用户投诉",
  BusinessEnquiry: "商业咨询",
  Other: "其他",
  Message: "消息",
  SubmitButton: "提交",
  INeedHelpCreatingMyGroupQ: "我需要帮助创建我的群体。",
  INeedHelpCreatingMyGroupQASectionOne:
    "首先，知道作为自己群体的创建者意味着您对其拥有绝对的控制权，所以即使是第一次创建也不必感到压力。这里有一个简单的指南。",
  DateAndTimeDescrGroups:
    "在这里，您选择群体的日期和会面时间。不要混淆上午时间：例如，星期天00:45可能在您脑海中仍然是星期六晚上，但在Join上是星期天早晨，这就是您需要列出的方式。",
  InviteYourFriendsDescrGroups:
    "这是您最初邀请朋友的步骤（不用担心，您可以之后再邀请更多人）。被邀请的成员将收到您的邀请通知，他们必须确认才能加入群体。您可以在没有邀请任何人的情况下创建群体，并让人们请求加入您。",
  MakeAStatement: "发表声明",
  MakeAStatementDescrGroups:
    "这是您为您的群体添加个人化元素的机会。可以是诸如“我们在庆祝我的生日”或“女孩穿白色连衣裙”之类的内容。",
  YourGroupIsReadyForCreation: "您的群体现在可以创建了！",
  INeedHelpCreatingMyEvent: "我需要帮助创建我的活动。",
  INeedHelpCreatingMyEventASectionOne:
    "首先，知道作为自己活动的创建者意味着您对其拥有绝对的控制权，所以即使是第一次创建也不必感到压力。这里有一个简单的指南。",
  EventTitle: "活动标题",
  EventTitleDescrEvents:
    "这必须是对您的活动的简要描述。比如“Chalandri的5x5足球比赛”、“秘密地点的海滩派对”或“Agia Paraskevi广场的放松聚会”。",
  DescribeYourEvent: "描述您的活动",
  DescribeYourEventDescrEvents:
    "现在是描述您的活动的机会。包括您希望在类似活动中看到的任何细节，由其他人创建。任何重要的内容都应包括在此。相信我们，如果您分析得当，那么更多的人以及更合适的人将对您的活动产生兴趣。",
  PrivateEvent: "私人活动",
  PrivateEventDescrEvents:
    "所有自定义活动默认是公开的，意味着Join的所有用户都可以查看和互动您的活动，除非您已经封锁了他们。如果您将活动切换为私密，则访问权限将仅限于您的朋友和您邀请的人。即使在活动创建后，您也可以更改活动的隐私设置。",
  DateAndTime: "日期和时间",
  DateAndTimeDescrEvents:
    "在这里，您选择您的活动开始的日期和时间。不要混淆上午时间：例如，星期天00:45可能在您脑海中仍然是星期六晚上，但在Join上是星期天早晨，这就是您需要列出的方式。",
  Category: "类别",
  CategoryDescrEvents:
    "我们得出的结论是您的活动很可能会属于以下类别之一：体育、派对、旅行或聚会。如果您无法清楚地看到您的活动属于这些类别中的任何一个，请选择“聚会”。毕竟，活动不能不属于聚会。",
  InviteYourFriends: "邀请您的朋友",
  InviteYourFriendsDescrEvents:
    "这是您最初邀请朋友的步骤（不用担心，您可以之后再邀请更多人）。被邀请的成员将收到您的邀请通知，他们必须确认才能加入活动。您可以在没有邀请任何人的情况下创建活动，并让人们请求加入您。",
  YourCustomEventIsReadyForCreation: "您的活动现在可以创建了！",
  CanICreateAPrivateGroupOrJoinAGroupPrivatelyQ:
    "我可以创建私人群体或私密加入群体吗？",
  CanICreateAPrivateGroupOrJoinAGroupPrivatelyA:
    "在“地点”和“验证活动”中的所有群体都是公开的。尽管如此，您可以将个人资料设置为私密，这样您的群体展示仅限于您的朋友和该特定群体的成员。",
  CanICreateAPrivateEventOrJoinAnEventPrivatelyQ:
    "我可以创建私人活动或私密加入活动吗？",
  CanICreateAPrivateEventOrJoinAnEventPrivatelyA:
    "与“地点”和“验证活动”的群体不同，您可以创建或加入私人自定义活动。如果您是自定义活动的创建者，您可以随时将活动从公开切换为私人，反之亦然。",
  AmIMakingAreservationWhenCreatingAGroupQ: "我创建群体时是在预定吗？",
  AmIMakingAreservationWhenCreatingAGroupA:
    "目前，Join 不会为您的群体进行桌子预订。",
  IAmHesitantToJoinQ:
    "我犹豫是否加入和创建 Join 上的群体，因为其他人会了解我的真实生活—社交生活。",
  IAmHesitantToJoinA:
    "我们深刻理解您对隐私的担忧，并尽力满足您的隐私需求，例如通过应用程序设置限制个人资料曝光，或创建私人活动，同时保持 Join 的功能，毕竟 Join 的核心在于将人们带到现实生活中。",
  CanIInviteFriendsToSomeoneElsesEventQ: "我可以邀请朋友参加其他人的活动吗？",
  CanIInviteFriendsToSomeoneElsesEventA:
    "可以的。只有在私人活动中，活动创建者会收到您邀请某人的通知，并且必须批准才能完成邀请。在公开活动中，就像在群体中一样，任何已加入的成员都可以自由邀请朋友，无需联系创建者。",
  IHaveBlockedSomeoneOnJoinQ:
    "我在 Join 上封锁了某人。他们能看到我加入的群体和活动吗？",
  IHaveBlockedSomeoneOnJoinA:
    "您在 Join 上封锁的人不能看到您是否已加入、创建或受邀参加群体或活动，除非他们也与该群体或活动有关。",
  WhatAmISeeingInTheHotPageQ: "我在“热门”页面看到的是什么？",
  WhatAmISeeingInTheHotPageA:
    "Join 的内容包括群体和自定义活动。群体可以在“地点”或“验证活动”下创建，而自定义活动是用户自行创建和定制的独立实体。我们创建了一个算法，根据您的个人资料与群体中个人资料的匹配程度，筛选、混合和展示这些内容在热门页面上。",
  WhyIsJoinAskingForAllThisProfileInfoQ: "为什么 Join 需要这些个人资料信息？",
  WhyIsJoinAskingForAllThisProfileInfoA:
    "我们尊重您对个人资料隐私的担忧，同时努力实现我们唯一的目标，就是将您与他人真正地联系起来。为了正确实现这一目标，我们需要了解您，您喜欢做什么，您在哪里学习或曾学习过，如果您是学生，以及您喜欢与什么样的人互动。",
  CanIOmitToDiscloseSomeProfileInfoAskedByJoinQ:
    "我可以省略一些 Join 要求的个人资料信息吗？",
  CanIOmitToDiscloseSomeProfileInfoAskedByJoinA:
    "我们通常建议用户不要跳过正确和充分地展示自己，因为这会影响他们在我们平台上的体验。尽管如此，在许多情况下，您可以选择“无”或“其他”作为答案，如果您不想披露我们要求的信息。",
  WhatISProfileStrengthQ: "什么是“个人资料强度”？",
  WhatISProfileStrengthA:
    "个人资料强度描述了您的个人资料在 Join 上的适当性和强度，基于当前状态。它不表示个人资料是否完整，并且仅对您可见。",
  DiscoverTheMainIdea: "发现主旨",
  SeeHowToInteract: "查看如何互动",
  MoreAboutEventCreation: "了解更多关于活动创建的信息",
  ContactUsTitle: "联系我们",
  ContactUsSubtitle: "联系我们以报告可能的问题、进行商业咨询或只是打个招呼！",
  SomethingAboutUs: "关于我们",
  SiteTitle: "Join | 群组、活动 & 人们",
  AppDescription:
    "Join 是一款专注于真实社交的移动应用。使用它可以加入聚会等群组和活动。创建账户，以前所未有的方式认识人们。",
  OpenInApp: "在应用中打开",
  FaqId1Q: "我需要帮助创建我的群组",
  FaqId1A:
    "首先，你需要知道，作为小组的创建者，你对它拥有完全的控制权。因此，不需要有任何压力，即使这是你第一次创建小组。以下是一个简单的指南。\n\n**日期和时间**\n\n在这里，你需要选择小组的日期和会面时间。请注意上午的时间，例如：周日 00:45 在你的认知里可能仍是周六晚上，但在 Join 上，它已是周日早晨，你需要按照这个方式填写。\n\n**邀请你的朋友**\n\n在这一步，你可以邀请朋友（别担心，之后仍然可以邀请更多）。受邀成员会收到你的邀请通知，并且他们需要确认接受才能加入小组。你也可以不邀请任何人，而让他们自己申请加入。\n\n**发表声明**\n\n这是你的机会，为小组添加个性化元素。例如，“我们在庆祝我的生日”或“女生们穿白色裙子”。\n\n现在，你的小组已准备就绪！",

  FaqId2Q: "我需要帮助创建我的活动",
  FaqId2A:
    "首先，你需要知道，作为自己活动的创建者，你对它拥有完全的控制权。因此，不需要有任何压力，即使这是你第一次创建活动。以下是一个简单的指南。\n\n**活动标题**\n\n标题应该是活动的简要描述。例如：“Chalandri 5v5 足球赛”、“秘密地点海滩派对”或“Agia Paraskevi 广场轻松聚会”。\n\n**描述你的活动**\n\n现在是你描述活动的机会。包含你认为在类似活动中应该有的重要信息。如果你足够详细，会吸引更多的参与者，并且是适合你的参与者。",

  FaqId3Q: "我可以创建私人群组或以私密方式加入群组吗？",
  FaqId3A:
    "所有‘地点’和‘大型活动’下的群组都是公开的。不过，你可以将你的个人资料设置为私密，这样你的信息只能被你的朋友或你加入的群组成员看到。",

  FaqId4Q: "我可以创建私人活动或以私密方式加入活动吗？",
  FaqId4A:
    "与‘地点’和‘大型活动’下的群组不同，你可以创建或加入**私人用户活动**。如果你是自定义活动的创建者，你可以随时将活动设为公开或私人。",

  FaqId5Q: "创建群组时，我是在预订场地吗？",
  FaqId5A: "目前，Join **不**会为你的群组进行餐桌或场地预订。",

  FaqId6Q: "我担心在 Join 上创建或加入群组会暴露我的真实社交生活",
  FaqId6A:
    "我们完全理解你对隐私的担忧，并尽最大努力满足你的隐私需求，例如允许你在应用的设置中限制个人资料的曝光，或创建私人活动，同时保持 Join 作为**促进线下社交的平台**的核心功能。",

  FaqId7Q: "我可以邀请朋友参加其他人的活动吗？",
  FaqId7A:
    "可以。如果是私人活动，活动创建者会收到你邀请朋友的请求，并需要批准后，邀请才能生效。在**公开活动**中，和群组一样，任何加入的成员都可以自由邀请朋友，无需创建者批准。",

  FaqId8Q: "我在 Join 上屏蔽了某人，他们还能看到我加入的群组和活动吗？",
  FaqId8A:
    "你在 Join 上屏蔽的用户**无法**看到你加入、创建或被邀请的群组或活动，除非他们也与该群组或活动有关。",

  FaqId9Q: "‘热门页面’上展示的内容是什么？",
  FaqId9A:
    "Join 上的内容由**群组**和**用户活动**组成。群组可以创建在‘地点’或‘已验证活动’下，而用户活动是由用户自由创建的独立活动。我们的算法会根据你的资料匹配程度筛选群组和活动，并在‘热门页面’上展示最适合你的内容。",

  FaqId10Q: "为什么 Join 需要这么多个人资料信息？",
  FaqId10A:
    "我们尊重你对隐私的担忧，但 Join 的唯一目标是**让现实生活中的人们聚在一起**。为了准确地实现这一目标，我们需要了解你的兴趣、你的学习背景（如果你是学生）以及你喜欢与什么样的人社交。",

  FaqId11Q: "我可以不提供 Join 要求的某些个人资料信息吗？",
  FaqId11A:
    "我们建议用户完整填写个人资料，以便获得更好的使用体验。不过，在许多情况下，你可以选择‘无’或‘其他’作为答案，如果你不愿透露某些信息。",

  FaqId12Q: "'个人资料评分' 是什么？",
  FaqId12A:
    "个人资料评分表示你的个人资料在 Join 上的适当性和强度，基于其当前状态。它不表示是否完整，并且仅对你可见。",

  FaqId13Q: "这两个选项是什么？",
  FaqId13A:
    "在此阶段，你会看到两个选项。\n\n第一个选项是创建你的 **用户活动**。点击 -用户活动- 按钮，创建你自己的活动，无论是派对、体育活动、聚会，甚至是旅行！\n\n第二个选项是选择一个 **商家** 或 **大型活动** 来创建你的 **小组**。如果你想要的地点是 Join 上已有的商家或大型活动，选择此选项。",

  FaqId14Q: "‘用户活动’和‘群组’有什么区别？",
  FaqId14A:
    "一个 **用户活动** 是由 Join 的其他成员创建的活动，就像你一样。而 **大型活动** 类似于 **商家**，它们由 Join 团队上传。\n\n**用户活动** 具有自己的独立存在，而 **小组** 仅显示在商家或大型活动之下。",
};

export default zh;
