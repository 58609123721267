import React from "react";

function Terms() {
  return (
    <>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="Generator" content="Microsoft Word 15 (filtered)" />
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:"Noto Sans Symbols";\n\tpanose-1:2 11 6 4 2 2 2 2 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\n.MsoChpDefault\n\t{font-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:595.45pt 841.7pt;\n\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n',
        }}
      />
      <div className="WordSection1">
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "24.0pt", lineHeight: "130%" }}
            >
              TERMS OF USE
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              Last revised on the 22th of March 2025
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You are reading the most recent version of Join’s Terms of Use
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Key change in this version: This is the first version.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Welcome to Join. The following Terms of Use are between you and Join
            Social App.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              The term “member”{" "}
            </span>
          </b>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            applies to all people included in the community on Join, meaning:
            All active profiles/ users of the platform.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              The term “non-member”
            </span>
          </b>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            applies to people who are not Join users that a Join user may
            interact with in real life, in an environment or circumstances, or
            in a setting that was published on the platform.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              The term “you”
            </span>
          </b>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            refers to the individual accessing the Service, or the company, or
            other legal entity on behalf of which such individual is accessing
            or using the Service, as applicable.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              The term “Service”
            </span>
          </b>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            refers to the Application
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              The terms “App” and “Application”
            </span>
          </b>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            means the software program downloaded by You on any electronic
            device, named Join.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "13.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              1a. Acceptance of Terms.
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            By creating an account on Join you agree to (i) the{" "}
          </span>
          <span lang="EN-US">
            <a href="https://join-socialapp.com/en/terms_of_use">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "#1155CC",
                }}
              >
                Terms of Use
              </span>
            </a>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            , (ii){" "}
          </span>
          <span lang="EN-US">
            <a href="https://join-socialapp.com/en/privacy_policy">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "#1155CC",
                }}
              >
                Privacy Policy
              </span>
            </a>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            and (iii) our{" "}
          </span>
          <span lang="EN-US">
            <a href="https://join-socialapp.com/en/cookie_policy">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "#1155CC",
                }}
              >
                Cookie Policy
              </span>
            </a>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            . If you do not accept and agree to be bound by all of the terms of
            this Agreement, please do not use the Service. Note that although
            this agreement is constructed based on the law of Greece, you must
            adhere to it irrespective of your location.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              1b. Changes to Terms of Use.
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            At times, we may ask you to review this Agreement. This is because
            we may make changes to the Service and to the Agreement in order to
            ameliorate the user experience and/or adopt changes reflecting the
            requirements of the law. The most recent version is the version in
            effect. As a registered user, you may always find it{" "}
            <b>under the Legal Section in the App’s Settings</b>. In case we
            have decided to update this version of the Agreement, with changes
            that involve material changes to your rights and obligations, we
            will notify you in time via email or by a notification so that you
            can review the changes, unless applicable law prevents us from doing
            so. Your continuing to use Join after the changes become effective
            automatically renders you compliant with the revised Agreement.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              2. Eligibility.
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Creating an account or accessing or using the Service is
            unauthorized unless all of the following are true:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You are at least 18&nbsp; years of age
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You will adhere to this Agreement and every applicable local,
            national or international law, rule and regulation.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            You are not prohibited from receiving Join’s services under any
            applicable laws
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You are eligible to form a binding contract with Join
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            The use of the Service is not prohibited for you under the laws of
            Greece or any other relevant jurisdiction.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You are not a person convicted of a criminal offense or felony or
            crime of similar severity, a sex crime or any other type of crime of
            violence
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            We have never previously disabled your account for breaches of our
            Terms.&nbsp; Otherwise, you are not eligible to create a new
            account.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              3. Your Account.
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "130%" }}
            >
              3.1 Creating an account.
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Creating an account can be done in two ways: Using your email or a
            third party login service with your existing account. For example,
            if you choose to use Google login, you authorize us to access and
            use certain information from your Google account, such as your
            google account’s e-mail. Please have a look at what data we may
            collect from you and how we may use it in the{" "}
          </span>
          <span lang="EN-US">
            <a href="https://join-socialapp.com/en/privacy_policy">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "#1155CC",
                }}
              >
                Privacy Policy{" "}
              </span>
            </a>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            section.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "130%" }}
            >
              3.2 You are solely responsible for your credentials
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Join has no legal responsibility in the event of your failing to
            maintain the confidentiality of your credentials. Each user is
            solely responsible for all activities that occur under their
            personal credentials.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              4. Altering the Service &amp; Termination.
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "130%" }}
            >
              4.1 Alteration of the Service
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Join’s team may partly alter, add new, remove entirely any of the
            existing features of the App, or otherwise update the software that
            you use or download, whilst always trying to ameliorate the overall
            user experience. Only in the event that the actions outlined above
            materially affect your rights and/ or obligations will we provide
            notice.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "130%" }}
            >
              4.2 Termination of Account
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You have the right to terminate your account at any time, for any
            reason:{" "}
            <b>
              Navigate to your Profile Page - Settings - Account - Delete
              Account and follow the simple steps
            </b>
            . Join may suspend or terminate your account at any time without
            notice if it believes that you have violated this Agreement.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            After your account is terminated, this Agreement will terminate,
            except that the following provisions will still remain in place:
            Sections 4, 5.1, 6.2, 6.3, sections 7-11{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              5. Safety/ Your Interactions with Other Members and non-Members.
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "130%" }}
            >
              5.1 Exemption of Responsibility
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Join is not responsible for the behavior of any user or member of
            the service or outcomes that may involve interactions of a user with
            a user, member or non-member of Join in real life or in a setting.
            You are responsible for using caution whenever agreeing to interact
            with other members of the service online, in the App and with
            members and non-members offline, in person. You are aware,
            understand and agree with the following: Join is not responsible for
            carrying out criminal history, or any other type of profile
            background checks on its members. Join makes no warranties or
            guarantees concerning the behavior compatibility, kindship and
            affinity of members. Join takes no responsibility when suggesting
            possible profiles as a “social match”. Any personal information you
            chose to directly or indirectly disclose, such as, but not limited
            to the location of a meeting, the people you will be meeting with,
            the time, etc. , when posting or joining an Event or Group meeting
            are exclusively shared at your own risk and consent. You are solely
            responsible for your online and offline interactions with other
            members and non-members of the platform.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "130%" }}
            >
              5.2 Prohibited ways of use
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You agree that you will not use the app as a communication means to
            organize or otherwise get involved in any type of unlawful actions
            and that you will not share any of your financial information: for
            example, your credit/debit card or bank account information, or wire
            or otherwise send money to other members. You may not use Join’s
            services in actions that{" "}
            <span style={{ background: "white" }}>
              infringe someone else's rights, including violation of their
              intellectual property rights.
            </span>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              5.3 Community Rules &amp; Guidelines
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            You understand and agree that you will not do any of the following:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Use Join for any purpose that is unlawful, harmful, improper or any
            otherwise by-this-Agreement prohibited interest{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Impersonate or provide untruthful information about you in your
            account
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Upload copyright-protected content without the proprietor’s
            permission
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Create and maintain more than one accounts
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Exchange or use other members’ accounts
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Create a new account if Join has terminated your original account
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Directly abuse or harass other members, or assist or prompt other
            members to engage in any targeted abuse or harassment against any
            other member.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Stalk, threaten, bully, verbally or physically assault or defame any
            member and non-member
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Create and publish fake Groups or fake Events
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Share and promote fake profiles, Groups and Events, the fraudulence
            of which you are aware of
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Post pornographic content or content that entails nudity or sexually
            explicit elements{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Share content that promotes racism or violence against a social
            group or a specific individual
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Publicly display or otherwise convey any financial information such
            as your card details, credentials, or other financial information of
            yours or of others
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Publish information private to other members or people without their
            consent
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            lineHeight: "130%",
          }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              6. Your Rights as a user of Join.
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "130%" }}
            >
              6.1 Your license and applicable rules
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            As a user of Join, you are entitled to a non-sublicensable,
            personal, non-exclusive, worldwide, royalty-free, non-transferable
            and irrevocable license that grants you access and permission of
            using the service. The authorization of you using the service of
            Join as intended and designed by Join is the only purpose of the
            aforementioned license and this permission automatically ceases to
            be valid if any of the following are true:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You encourage or promote any actions that violate this Agreement.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You exploit the App or elements contained in the App for any
            commercial purposes without our written consent.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You disseminate, copy as is, modify entirely or create/reproduce
            derivative works from or make use of any copyrighted material or
            intellectual property such as images, trademarks &amp; trade names
            or any other content or non-generic input obtained through the
            Service, mirror or sublicense any part of Join’s service without our
            written authorization.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You state or imply that any statements you may make are accredited
            or approved by Join, unless you have our written consent.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            You upload viruses, other types of malicious code, use the services
            to send spam, or do anything else that could compromise, disable,
            overburden, interfere with or impair the proper working, integrity,
            operation or appearance of the Service{" "}
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            or the servers or networks connected to the Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            You access or collect data from the App using automated means,
            without our written consent or attempt to access data that you are
            not permitted to access.&nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            You proxy, collect or request usernames, passwords or misappropriate
            access tokens
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            obtained through the service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You create or use any type of third-party software or means that
            interacts with Join or the non-generic information of its users
            without our written authorization.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You scan or test the integrity and impenetrability of the App or any
            system or network of the Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            lineHeight: "130%",
          }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "130%" }}
            >
              6.2 Actions against misuse of the Apps services
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            It is the user’s sole responsibility to abide by the community's
            rights and to respect this agreement. Even though Join does not
            guarantee that all content will comply with this agreement, Join
            reserves the right to take any of the following steps if we, in our
            sole judgment, determine unlawful or unauthorized use of the App’s
            services or disrespectful conduct towards other members and
            non-members or Join’s customer care:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            Suspension, temporary deactivation or termination of your account
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Withdrawal of shared/ uploaded contents{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Legal Action
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
              background: "white",
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Removal or restriction of your access to content, data, features
            and/or services
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            With the aim of{" "}
            <span style={{ background: "white" }}>
              protecting and supporting our community, we highly encourage you
              to submit a report of conduct or content that may have arisen your
              suspicion or evidently violates your rights or this agreement.{" "}
            </span>
            Please
            <b>
              navigate to your Profile Screen - Settings - Report a Problem,
            </b>{" "}
            and report the matter as a violation of Joins Community Standards.
            <span style={{ background: "white" }}>
              {" "}
              We advise you to use the same form to contact us if you think that
              we have unjustifiably disabled your account.{" "}
            </span>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              6.3 Proprietorship retention
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            You invariably retain ownership of the intellectual property rights:
            copyright or trademarks in any respective content that you create or
            share on Join. This agreement states that you remain entirely free
            to use your content whenever and wherever you wish.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            If you determine that content, over which you possess proprietorship
            or copyright protected content has been copied or otherwise used in
            a way that constitutes copyright infringement, you may{" "}
          </span>
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "12.0pt", lineHeight: "130%" }}
            >
              navigate to your Profile Screen - Settings - Report a Problem,
            </span>
          </b>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            and report
            <span style={{ background: "white" }}>
              {" "}
              the issue. You must include the following in your text to support
              your statement:
            </span>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "18.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            1)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Your email address, phone number and the copyright owner’s full
            name. We may ask you to disclose further information as to the
            aforementioned.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "18.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            2)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Location description of the infringement, how and where to find the
            violation on the Service
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "18.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            3)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            A description of the content for which you claim an infringement
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You must also send a copy of a document to{" "}
          </span>
          <span lang="EN-US">
            <a href="mailto:info@join-socialapp.com">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "#1155CC",
                }}
              >
                info@join-socialapp.com
              </span>
            </a>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            that:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            a)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Grants a written permission to the person authorized to act on
            behalf of the copyright owner
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            b)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Includes a written statement by the copyright owner that the
            disputed use of the regarded content has not been authorized by
            them, an agent, or by law.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            c)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Includes a statement under oath, made under penalty of perjury
            either by you or the copyright owner, that all the information you
            provided is truthful and accurate
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            d)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Is electronically or physically signed by you (being the authorized
            person) and the copyright owner, except if the copyright owner is
            you. In this instance one signature is sufficient.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            All the above-mentioned elements are required. Reports on copyright
            infringement that do not fulfill the inclusion of all the required
            elements will be regarded insufficient and Join may omit them
            entirely.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "13.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              7. Join’s Rights.
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "130%" }}
            >
              7.1 The license you grant Join
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            By this agreement, you grant Join a license and right to use, store,
            modify, display, adapt, reproduce, publish and distribute data,
            information, feedback and content you provide Join directly by
            uploading or posting or indirectly via third parties such Google
            Authentication or otherwise rendering available and displayable on
            the App without you being entitled to compensation. The above-stated
            include but are not limited to personal information such as your
            Name, Age, Educational Institution, Sexuality, favorable Hobbies
            &amp; Interests, Photos, your interactions with other users and
            more. Whenever we need a new authorization from you, in order to
            access specific features, we will specifically ask you to grand it
            to us. You understand and agree that any Content you make available
            on the Service or that you authorize us to make available on the
            Service may be viewed by other users/ members or non-members who may
            receive shared content from other Join users.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            This license is worldwide, sublicensable, transferable,
            non-exclusive, worldwide, and royalty-free.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "130%" }}
            >
              7.2 Disclosure of content
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You understand that Join has the right, by enforcing this agreement
            with you, to store, access, and disclose any data, information,
            feedback and content you directly or indirectly provide Join if we
            are required or advised to do so by law or we judge that there are
            reasonable grounds to believe that such actions would satisfy a
            legitimate interest, including the following:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Inspection of claims that you have violated this Agreement
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Customer service in response to your requests
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Protection of the security and integrity of the App, systems and
            services or of the safety of any other person, user/member or
            ex-user/ member
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            To defend and protect our users, our partners and ourselves
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "130%" }}
            >
              7.3 Private account enablement
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You may limit your profile's exposure by turning on Private mode
            under the respective settings page:{" "}
            <b>
              <span style={{ background: "white" }}>
                Navigate to Your Profile Page - Settings - Your Privacy and
                lastly toggle the switch to activate Private Mode
              </span>
            </b>
            . Beware that activating Private mode could consequently limit your
            experience on Join.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "130%" }}
            >
              7.4 Mandatory truthfulness of placed content
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You agree that any data you make available on the Service or that
            you authorize us to make available on the Service is accurate and
            truthful. Join has full authority to review, judge and decide to
            partly or entirely delete content that violates this Agreement or is
            not aligned with or could damage the reputation of the App.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "13.0pt", lineHeight: "130%" }}
            >
              7.5 Content storage timeframe
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            You agree that the aforementioned data, information, feedback and
            content will be stored and accessible by Join on our database for
            the whole duration of your active account and in the event of a
            terminated account, for an additional 90 (ninety) days counting from
            the termination day. Where deletion of your content is impossible
            due to technical reasons, you understand and agree that we will
            complete the deletion when technically feasible. Other reasons of
            not allowing immediate deletion after the period of 90 days post
            deletion request of your account would be to{" "}
            <span style={{ background: "white" }}>
              protect the integrity and security of our App, systems and
              services, and/or to protect or defend our community: partners,
              users/ members, ex-users/ members, ourselves or any other person.
            </span>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              7.6 Third-party services and Advertisement
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Join has the right to display various types of advertisements and
            promotions, such as to directly place ads, sponsored and commercial
            content of our choice or indirectly by offering the service of
            advertising via Join to third parties, partners without compensating
            you. Advertisements and promotions may be conducted via links to
            other websites, platforms or resources and Join does not claim
            responsibility or make warranties for the availability or lack of
            availability of such external means. Join is in no event liable for
            third parties’ terms and conditions or actions.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{
                fontSize: "13.0pt",
                lineHeight: "130%",
                background: "white",
              }}
            >
              7.7 Necessary Contact{" "}
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            By creating and maintaining an account you agree to being contacted
            at your registered email address when this constitutes a necessary
            corollary of your activity on the Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "13.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              8. Disclaimer of Warranties and Limitation of Liability
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Join assumes no responsibility for errors or omissions in the
            contents of the Service. The service is provided by Join on an “as
            is” and “as available” basis. In no event shall Join be liable for
            any, but without limitation to the following: special, direct,
            indirect, consequential, or incidental damages or any damages
            whatsoever, whether in an action of contract, negligence or another
            tort, arising out of or in connection with the use of the Service or
            the contents of the Service and does not make warranties of
            satisfactory quality, merchantability, fitness for a particular
            purpose or non-infringement. Join reserves the right to make
            additions, deletions, or modifications to the contents of the
            Service at any time without prior notice, unless these changes
            materially affect your rights and/ or obligations. In such instances
            will we provide notice. Join does not represent or make any
            warranties that the service will be uninterrupted, secure and free
            of viruses or other harmful components or be error-free.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            The information on the Service is provided with the understanding
            that Join is not herein engaged in rendering legal, accounting, tax,
            or other professional advice and services. As such, it should not be
            used as a substitute for consultation with professional accounting,
            tax, legal or other competent advisers.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            lineHeight: "130%",
          }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            In no event shall Join be liable for any special, incidental,
            indirect, or consequential damages whatsoever arising out of or in
            connection with your access or use or inability to access or use the
            Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Join reserves no responsibility and makes no warranty regarding any
            content that you or any other user or member or third party shares,
            uploads, posts, sends or receives on the App. Any material
            downloaded or otherwise obtained via the Service is accessed at your
            own discretion and risk.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Join reserves no responsibility and makes no warranty regarding any
            conduct or behavior you or any other user, member and non-member of
            the Service may experience in real life in a setting or environment
            that was shared, published, or created on Join and makes no
            guarantee that the “Places”, “Big Events”, “User Events” or any
            other material that is uploaded by Join or by users and is depicted
            on the service will fulfill the expectations of the user in real
            life. Join makes no warranties that the information depicted in the
            name of each third party business or event is accurate. Pictures,
            open hours, locations and any other info related to Businesses or
            Events uploaded from Join’s authorized people and listed on the app
            may be inaccurate or un-updated.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", lineHeight: "130%", color: "black" }}
          >
            Join is not a booking platform and makes no guarantee that users
            will be granted entrance or will find a table or spot at any of the
            "Places," "Big Events," or "User Events" displayed on the app. Join
            reserves no responsibility and makes no warranty regarding your
            real-life experience related to any of the aforementioned locations.
            In no event shall Join be held liable for any dissatisfaction,
            damages, or issues arising from your access or interactions with
            these events or locations.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            Join will not be liable to You or anyone else for any decision made
            or action taken in reliance on the information given by the Service
            or for any consequential, special or similar damages, even if
            advised of the possibility of such damages.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "14.0pt",
            marginLeft: "36.0pt",
            textIndent: "-18.0pt",
            lineHeight: "130%",
          }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              fontFamily: '"Noto Sans Symbols"',
            }}
          >
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", lineHeight: "130%", color: "black" }}
          >
            To the fullest extent permitted by law, Join, its partners,
            employees, licensors, and service providers shall not be{" "}
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            liable for any ensuing, indirect, punitive, subsidiary, or auxiliary
            damages, including but not exclusive of the following: verbal or
            physical assault, death, loss of profits, loss of goodwill, loss of
            data or any other personal issues such as psychological damage or
            other health-related issues, whether incurred directly or
            indirectly, resulting from (I) your access to or use of or inability
            to use or access the Service (II) the behavior and conduct of other
            users or third parties online (III) your interactions with members
            and non-members on Join and in real life, (IV) your real-life
            experience related to any of the locations that are displayed on the
            App and (V) unauthorized access or use or amendment of your
            content.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              9.{" "}
              <strong>
                <span
                  style={{ fontFamily: '"Arial",sans-serif', color: "black" }}
                >
                  Featured Locations{" "}
                </span>
              </strong>
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="EN-US"
            style={{ fontSize: "12.0pt", lineHeight: "130%", color: "black" }}
          >
            Join does not enter into any paid affiliations, partnerships, or
            agreements with the businesses or events featured on the app, except
            where explicitly outlined in a separate contractual arrangement.
            Join does not utilize these businesses or events for the purpose of
            generating profit or diverting revenue away from them. Instead, Join
            provides complimentary advertising for these businesses and events
            by utilizing publicly available information sourced from the web,
            unless otherwise specified in a formal agreement with the respective
            parties.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              10. Disputes
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            In the event that you or Join make a claim or dispute relatable to
            your experience with the Service, as a service receiver, both you
            and Join understand and agree that you may resolve your individual
            claim or dispute against us, and we may resolve our claim or dispute
            against you in any competent court in the region of Attica, Greece
            that has jurisdiction over the claim or dispute. The laws of Greece
            will apply without regard to conflict of law provisions.&nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              11. How to contact us
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            If you have any questions about this Privacy Policy, please use this{" "}
          </span>
          <span lang="EN-US">
            <a href="https://join-socialapp.com/en/contact">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "#1155CC",
                }}
              >
                contact form
              </span>
            </a>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            .
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span
            lang="EN-US"
            style={{
              fontSize: "12.0pt",
              lineHeight: "130%",
              background: "white",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <b>
            <span
              lang="EN-US"
              style={{ fontSize: "18.0pt", lineHeight: "130%" }}
            >
              12. Entire Agreement.
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "14.0pt", lineHeight: "130%" }}
        >
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            This Agreement, in which are entailed the{" "}
          </span>
          <span lang="EN-US">
            <a href="https://join-socialapp.com/en/terms">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "#1155CC",
                }}
              >
                Terms of Use
              </span>
            </a>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            ,{" "}
          </span>
          <span lang="EN-US">
            <a href="https://join-socialapp.com/privacy_policy">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "#1155CC",
                }}
              >
                Privacy Policy
              </span>
            </a>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            {" "}
            and our{" "}
          </span>
          <span lang="EN-US">
            <a href="https://join-socialapp.com/en/cookie_policy">
              <span
                style={{
                  fontSize: "12.0pt",
                  lineHeight: "130%",
                  color: "#1155CC",
                }}
              >
                Cookie policy
              </span>
            </a>
          </span>
          <span lang="EN-US" style={{ fontSize: "12.0pt", lineHeight: "130%" }}>
            , constitutes the entire and only valid agreement between you and
            Join concerning the use of the App.{" "}
            <span style={{ background: "white" }}>
              It supersedes any prior agreements
            </span>
            . In the event that any term or provision of this Agreement is
            unenforceable, the{" "}
            <span style={{ background: "white" }}>
              unenforceable portion will be deemed amended to the minimum extent
              necessary to make it enforceable and
            </span>{" "}
            the remainder of this Agreement shall continue in full force and
            effect. If Join fails to exercise or enforce any right or provision
            of this Agreement,{" "}
            <span style={{ background: "white" }}>
              it will not be considered a waiver. Any amendment to or waiver of
              these Terms must be made in writing and signed by us.
            </span>{" "}
            You understand and agree that You will not transfer any of your
            rights or obligations under these Terms to anyone else without our
            consent and that your account on Join is non-transferable meaning
            all of your rights to your account and its Content terminate upon
            your death. No affiliation, partnership, joint venture or any other
            type of bind or special relationship or employment is created in a
            succession of this Agreement and in no event are you allowed to make
            any representations on behalf of or to bind Join by any means.
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN-US">&nbsp;</span>
        </p>
      </div>
    </>
  );
}

export default Terms;
