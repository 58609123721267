const fr = {
  Slogan: "Real Socializing",
  AvailableIn: "Disponible dans",
  AvailableNow: "Disponible maintenant",
  Days: "JOURS",
  Hours: "HRS",
  Minutes: "MIN",
  Seconds: "SEC",
  Features: "Fonctionnalités",
  JoinGroupsOfHeader: "REJOIGNEZ DES GROUPES DE PERSONNES INTÉRESSANTES",
  JoinGroupsOfTextOne: "Rencontrer des gens n'a jamais été aussi facile.",
  JoinGroupsOfTextTwo:
    "Découvrez des groupes de personnes intéressantes qui se retrouveront à un certain endroit, lors d'un grand événement ou d'un événement créé par un utilisateur. Demandez à les rejoindre ou aimez leur profil afin qu'ils puissent répondre en vous invitant.",
  FriendsAndDatesHeader: "AMIS ET RENCARDS QUI VOUS CONVIENNENT",
  FriendsAndDatesTextOne:
    "Rencontres ou socialisation pure, c'est vous qui décidez.",
  FriendsAndDatesTextTwo:
    "Ne manquez pas l'occasion de rencontrer des personnes qui correspondent à votre type. Les personnes ayant des traits et des préférences de rencontre correspondants apparaîtront comme une Correspondance Sociale. Nous adorons vous connecter avec les bonnes personnes pour vous !",
  CreateUniqueEventsHeader: "CRÉEZ VOS PROPRES GROUPES ET ÉVÉNEMENTS UNIQUES",
  CreateUniqueEventsTextOne:
    "Envie d'organiser une fête ou de jouer au football avec vos amis ?",
  CreateUniqueEventsTextTwo:
    "En plus de créer et de rejoindre des groupes dans les endroits et les grands événements que nous avons répertoriés, vous pouvez également créer vos propres événements personnalisés. Rendez-le massif ou gardez-le privé, le choix est le vôtre.",
  Legal: "Légal",
  PrivacyPolicy: "Politique de Confidentialité",
  Terms: "Conditions",
  CookiePolicy: "Politique de Cookies",
  ContactUs: "Contactez-nous",
  Download: "Télécharger !",
  AboutUs: "À propos de nous",
  RealSocializing: "Socialisation Réelle",
  WhyToGetJoinHeader: "Pourquoi obtenir Join ?",
  WhyToGetJoinTextOne:
    "Nous doutons que vous utilisiez même une seule application qui promeut véritablement votre vie sociale. Pas de mal pour les grands noms comme Instagram ou Facebook : jusqu'à présent, il y a quelques plateformes “sociales” réussies - mais en réalité, ce sont des plateformes polyvalentes et ce, pour une bonne raison. Mais nous offrons une dimension à la socialisation qui manquait aux applications sociales existantes jusqu'à récemment.",
  WhyToGetJoinTextTwo:
    "Nous revendiquons fièrement être la première véritable application sociale, car nous ne faisons qu'une seule chose au mieux : nous rassemblons les gens dans la vie réelle. Utiliser Join signifie accéder à des groupes de personnes qui ont prévu de visiter des établissements d'accueil et de loisirs, tels que des bars, des discothèques, des cafétérias, des événements musicaux et plus encore. Il y a aussi des fêtes, des matchs de sport et d'autres événements auxquels vous pouvez participer. En plus de vous aider à planifier des activités amusantes avec vos amis actuels, nous avons également fait de vous donner l'opportunité de vous faire de nouveaux amis et partenaires de qualité notre priorité. Oubliez le sentiment de malaise, car vous pouvez maintenant rencontrer les bonnes personnes avec un minimum d'effort. Nous ne pouvons pas vraiment expliquer comment en quelques phrases, mais nous avons veillé à briser la glace une fois pour toutes. Join est loin d'être une autre plateforme de rencontre maladroite dont les gens ont honte d'utiliser.",
  OurHistoryHeader: "Notre Histoire - Join & L'Équipe",
  OurHistoryTextOne:
    "Covid-19 a bouleversé le monde. Ce que la quarantaine a fait à la Grèce, en termes sociaux, c'est qu'elle a éliminé la socialisation de la vie des gens et écrasé son foyer, l'industrie de l'accueil. C'est là que Join entre en jeu.",
  OurHistoryTextTwo:
    "La vérité est que nos relations humaines n’étaient plus les mêmes après la pandémie. Certaines sont devenues plus faibles et d’autres plus fortes, tandis que plusieurs ont été abandonnées complètement.\n\n-People ont distingué qui compte vraiment pour eux-\n\nITALIC“Cela dit, lors d'une soirée ennuyeuse de la quarantaine de 2021, j'ai décidé de connecter les bars, clubs et cafétérias manqués à l'époque, pour ne nommer que quelques-uns, avec le besoin des gens de créer des relations réelles. Le résultat est aujourd'hui appelé Join, une application mobile disponible sur l'App Store et Google Play, et je suis Dimitris Chatzichristofas, son co-fondateur.\n\nITALICCréer Join n'a pas été un processus facile et c’est pourquoi je suis reconnaissant d’avoir eu deux excellents développeurs et co-fondateurs, George Bratsiotis et Chris, qui ont développé l'application de zéro pendant que je m'occupais de la plupart des tâches commerciales. Nous avons encore beaucoup de travail à faire et nous sommes impatients de voir ce qui nous attend.“",
  FullName: "Nom Complet",
  Subject: "Objet",
  AccountOrPrivacyIssue: "Problème de Compte / Confidentialité",
  ReportInappropriateBehaviour: "Signaler un Comportement Inapproprié",
  UserComplaint: "Réclamation Utilisateur",
  BusinessEnquiry: "Demande Commerciale",
  Other: "Autre",
  Message: "Message",
  SubmitButton: "Envoyer",
  INeedHelpCreatingMyGroupQ: "J'ai besoin d'aide pour créer mon groupe.",
  INeedHelpCreatingMyGroupQASectionOne:
    "Tout d'abord, sachez qu'en tant que créateur de votre propre groupe, vous avez un contrôle total sur celui-ci, donc pas de stress, même si c’est le premier que vous créez. Voici un guide simple.",
  DateAndTimeDescrGroups:
    "Ici, vous choisissez la date et l'heure de la réunion de votre groupe. Ne vous laissez pas tromper par les heures a.m. : par exemple, dimanche, 00:45 peut encore être samedi soir dans votre esprit, mais sur Join c’est dimanche matin et c’est ainsi que vous devez le noter.",
  InviteYourFriendsDescrGroups:
    "C'est l'étape où vous invitez initialement vos amis (ne vous inquiétez pas, vous pouvez en inviter plus par la suite). Les membres invités recevront une notification d'invitation de votre part, à laquelle ils devront répondre positivement s'ils souhaitent rejoindre le groupe. Vous pouvez créer un groupe sans inviter personne et laisser les gens demander à vous rejoindre.",
  MakeAStatement: "Faire une Déclaration",
  MakeAStatementDescrGroups:
    "C'est votre chance d'ajouter une touche personnelle à votre groupe. Cela peut être quelque chose comme « Nous célébrons mon anniversaire » ou « Les filles portent une robe blanche ».",
  YourGroupIsReadyForCreation: "Votre groupe est maintenant prêt à être créé !",
  INeedHelpCreatingMyEvent: "J'ai besoin d'aide pour créer mon événement.",
  INeedHelpCreatingMyEventASectionOne:
    "Tout d'abord, sachez qu'en tant que créateur de votre propre événement, vous avez un contrôle total sur celui-ci, donc pas de stress, même si c’est le premier que vous créez. Voici un guide simple.",
  EventTitle: "Titre de l'Événement",
  EventTitleDescrEvents:
    "Cela doit être une brève description de votre événement. Quelque chose comme « Football 5x5 à Chalandri », « Fête sur une plage à lieu secret », ou « Rencontre détendue sur la place d'Agia Paraskevi ».",
  DescribeYourEvent: "Décrivez votre Événement",
  DescribeYourEventDescrEvents:
    "Maintenant, c’est votre chance de décrire votre événement. Incluez tout détail que vous auriez voulu voir à un événement comme le vôtre, créé par quelqu'un d'autre. Tout ce qui est important, vous devez l'inclure ici. Croyez-nous, si vous êtes analytique, alors plus de gens et des personnes plus appropriées s'intéresseront à votre événement.",
  PrivateEvent: "Événement Privé",
  PrivateEventDescrEvents:
    "Tous les événements personnalisés sont ouverts par défaut, ce qui signifie que tous les utilisateurs de Join peuvent voir et interagir avec votre événement, sauf les personnes que vous avez bloquées. Si vous passez votre événement en privé, l'accès sera limité à vos amis et aux personnes que vous invitez. Vous pouvez modifier la confidentialité de votre événement même après sa création.",
  DateAndTime: "Date et Heure",
  DateAndTimeDescrEvents:
    "Ici, vous choisissez la date et l'heure auxquelles votre événement commencera. Ne vous laissez pas tromper par les heures a.m. : par exemple, dimanche, 00:45 peut encore être samedi soir dans votre esprit, mais sur Join c’est dimanche matin et c’est ainsi que vous devez le noter.",
  Category: "Catégorie",
  CategoryDescrEvents:
    "Nous avons conclu que votre événement se classera probablement dans l'une de ces catégories : Sports, Fête, Voyage, ou Rencontre. Si vous ne pouvez pas clairement voir votre événement appartenir à l'une de ces catégories, veuillez choisir « rencontre ». Après tout, un événement ne peut pas ne pas être une rencontre.",
  InviteYourFriends: "Invitez vos Amis",
  InviteYourFriendsDescrEvents:
    "C'est l'étape où vous invitez initialement vos amis (ne vous inquiétez pas, vous pouvez en inviter plus par la suite). Les membres invités recevront une notification d'invitation de votre part, à laquelle ils devront répondre positivement s'ils souhaitent rejoindre l'événement. Vous pouvez créer un événement sans inviter personne et laisser les gens demander à vous rejoindre.",
  YourCustomEventIsReadyForCreation:
    "Votre événement est maintenant prêt à être créé !",
  CanICreateAPrivateGroupOrJoinAGroupPrivatelyQ:
    "Puis-je créer un groupe privé ou rejoindre un groupe de manière privée ?",
  CanICreateAPrivateGroupOrJoinAGroupPrivatelyA:
    "Tous les groupes dans les Lieux et Événements Vérifiés sont publics. Toutefois, vous pouvez rendre votre profil privé, ce qui limite votre apparence dans les groupes à seulement vos amis et aux membres de ce groupe spécifique.",
  CanICreateAPrivateEventOrJoinAnEventPrivatelyQ:
    "Puis-je créer un événement privé ou rejoindre un événement de manière privée ?",
  CanICreateAPrivateEventOrJoinAnEventPrivatelyA:
    "Contrairement aux groupes dans les Lieux et Événements Vérifiés, vous pouvez créer ou rejoindre des Événements Personnalisés privés. Si vous êtes le créateur d'un événement personnalisé, vous pouvez à tout moment passer votre événement de public à privé et inversement.",
  AmIMakingAreservationWhenCreatingAGroupQ:
    "Est-ce que je fais une réservation en créant un groupe ?",
  AmIMakingAreservationWhenCreatingAGroupA:
    "Pour le moment, Join ne fait pas de réservations de table pour vos groupes.",
  IAmHesitantToJoinQ:
    "Je suis hésitant à rejoindre et créer des groupes sur Join, car d'autres personnes pourraient connaître ma vie réelle – ma vie sociale.",
  IAmHesitantToJoinA:
    "Nous comprenons profondément votre préoccupation concernant votre vie privée et nous faisons de notre mieux pour répondre à vos besoins en matière de confidentialité, par exemple en vous offrant la possibilité de restreindre l'exposition de votre profil via les paramètres de l'application ou de créer des événements privés, tout en gardant Join fonctionnel, étant une application qui a pour but de rassembler les gens dans la vie réelle.",
  CanIInviteFriendsToSomeoneElsesEventQ:
    "Puis-je inviter des amis à l'événement de quelqu'un d'autre ?",
  CanIInviteFriendsToSomeoneElsesEventA:
    "Oui, vous pouvez. Seulement si c'est un événement privé, son créateur recevra une notification de votre intention d'inviter quelqu'un à son événement et devra l'approuver pour que l'invitation soit envoyée. Dans les événements ouverts, tout comme dans les groupes, tout membre peut librement inviter ses amis sans engager le créateur.",
  IHaveBlockedSomeoneOnJoinQ:
    "J'ai bloqué quelqu'un sur Join. Peut-il voir les groupes et événements auxquels je participe ?",
  IHaveBlockedSomeoneOnJoinA:
    "Les personnes que vous avez bloquées sur Join ne peuvent pas voir si vous avez rejoint, créé ou été invité à un groupe ou à un événement, à moins qu'elles ne soient également liées à ce groupe ou événement.",
  WhatAmISeeingInTheHotPageQ: "Que vois-je dans la page « Hot » ?",
  WhatAmISeeingInTheHotPageA:
    "Le contenu de Join est composé de Groupes et d'Événements Personnalisés. Les Groupes peuvent être créés sous « Lieux » ou sous « Événements Vérifiés », tandis que les Événements Personnalisés sont des entités autonomes créées et personnalisées par les utilisateurs eux-mêmes. Nous avons créé un algorithme qui filtre les groupes et les événements personnalisés en fonction de la façon dont votre profil correspond aux profils en eux, les mélange et les présente en conséquence dans la page Hot.",
  WhyIsJoinAskingForAllThisProfileInfoQ:
    "Pourquoi Join demande-t-il toutes ces informations de profil ?",
  WhyIsJoinAskingForAllThisProfileInfoA:
    "Nous respectons votre préoccupation concernant la confidentialité de votre profil, tout en nous efforçant de remplir notre seul but auprès de nos utilisateurs. Il n’est autre que de vous rassembler, en tant que personnes, dans la vie réelle. Pour y parvenir correctement, nous avons besoin que vous nous parliez de vous, de ce que vous aimez faire dans la vie réelle, où vous étudiez ou avez étudié, si vous êtes étudiant et quel type de personnes vous préférez côtoyer.",
  CanIOmitToDiscloseSomeProfileInfoAskedByJoinQ:
    "Puis-je omettre de divulguer certaines informations demandées par Join ?",
  CanIOmitToDiscloseSomeProfileInfoAskedByJoinA:
    "Nous conseillons généralement à nos utilisateurs de ne pas sauter sur la présentation correcte et adéquate d'eux-mêmes via leurs profils sur Join, car cela nuira à leur expérience sur notre plateforme. Toutefois, dans de nombreux cas, vous pouvez choisir « Aucun » ou « Autre » comme réponse, si vous ne souhaitez pas divulguer les informations que nous demandons sur vous.",
  WhatISProfileStrengthQ: "Qu'est-ce que la « Force du Profil » ?",
  WhatISProfileStrengthA:
    "La Force du Profil décrit à quel point votre profil sera adéquat et solide sur Join, en fonction de son état actuel. Elle ne décrit pas s'il est complet ou non et est uniquement visible par vous.",
  DiscoverTheMainIdea: "Découvrez l'idée",
  SeeHowToInteract: "Voir comment interagir",
  MoreAboutEventCreation: "En savoir plus sur la création d'événements",
  ContactUsTitle: "Contactez-nous",
  ContactUsSubtitle:
    "Contactez-nous pour signaler d'éventuels problèmes, pour des demandes commerciales ou simplement pour dire bonjour !",
  SomethingAboutUs: "À propos de nous",
  SiteTitle: "Join | Groupes, Événements & Personnes",
  AppDescription:
    "Join est une application mobile entièrement dédiée à la socialisation réelle. Utilisez-la pour rejoindre des groupes et des événements comme des fêtes et bien plus encore. Créez un compte et rencontrez des personnes d'une manière inédite.",
  OpenInApp: "Ouvrir dans l'application",
  FaqId1Q: "J'ai besoin d'aide pour créer mon groupe",
  FaqId1A:
   "Tout d'abord, sache que, en tant que créateur de ton groupe, tu as un contrôle total sur celui-ci. Il n'y a donc aucune raison de stresser, même si c'est ton premier groupe. Voici un guide simple.\n\n**Date et heure**\n\nIci, tu choisis la date et l'heure de rencontre de ton groupe. Fais attention aux heures du matin : par exemple, dimanche à 00h45 peut toujours sembler être samedi soir pour toi, mais sur Join, c'est déjà dimanche matin, et c'est ainsi que tu dois l’indiquer.\n\n**Invite tes amis**\n\nC'est l'étape où tu invites tes amis (pas d'inquiétude, tu pourras en inviter d'autres plus tard). Les membres invités recevront une notification d’invitation de ta part, qu’ils devront accepter pour rejoindre le groupe. Tu peux aussi créer un groupe sans inviter personne et laisser les autres demander à te rejoindre.\n\n**Fais une déclaration**\n\nC'est ta chance d'ajouter une touche personnelle à ton groupe. Par exemple, « Nous fêtons mon anniversaire » ou « Les filles portent une robe blanche ».\n\nTon groupe est maintenant prêt à être créé !",

  FaqId2Q: "J'ai besoin d'aide pour créer mon événement",
  FaqId2A:
   "Tout d'abord, sache que, en tant que créateur de ton propre événement, tu as un contrôle total dessus. Il n'y a donc aucune raison de stresser, même si c'est ton premier événement. Voici un guide simple.\n\n**Titre de l'événement**\n\nCe titre doit être une brève description de ton événement, comme « Foot 5x5 à Chalandri », « Soirée plage secrète » ou « Rencontre chill sur la place Agia Paraskevi ».\n\n**Décris ton événement**\n\nC'est maintenant l'occasion de décrire ton événement. Ajoute tous les détails que tu aurais aimé voir si un autre organisateur avait créé un événement similaire. Tout ce qui est important doit être mentionné ici. Fais-nous confiance : plus tu es précis, plus tu attireras de monde, et des participants qui te correspondent vraiment.",

  FaqId3Q: "Puis-je créer un groupe privé ou rejoindre un groupe en privé ?",
  FaqId3A:
    "Tous les groupes des Lieux et Grands Événements sont publics. Cependant, vous pouvez rendre votre profil privé, limitant ainsi votre visibilité aux seuls amis et membres du groupe.",

  FaqId4Q:
    "Puis-je créer un événement privé ou rejoindre un événement en privé ?",
  FaqId4A:
    "Contrairement aux groupes des Lieux et Grands Événements, vous pouvez créer ou rejoindre des Événements Privés. Si vous êtes le créateur d’un événement personnalisé, vous pouvez à tout moment le passer d’ouvert à privé et inversement.",

  FaqId5Q: "Créer un groupe équivaut-il à faire une réservation ?",
  FaqId5A:
    "Pour l’instant, Join ne permet pas de réserver des tables pour vos groupes.",

  FaqId6Q:
    "Je suis hésitant à créer ou rejoindre des groupes sur Join, car les autres pourraient en apprendre sur ma vie sociale réelle",
  FaqId6A:
    "Nous comprenons vos préoccupations en matière de confidentialité et faisons de notre mieux pour y répondre, par exemple en vous permettant de restreindre la visibilité de votre profil via les paramètres de l’application ou de créer des événements privés, tout en restant fidèle à la mission de Join : réunir les gens dans la vraie vie.",

  FaqId7Q:
    "Puis-je inviter des amis à un événement créé par quelqu’un d’autre ?",
  FaqId7A:
    "Oui, vous le pouvez. Cependant, si l’événement est privé, son créateur recevra une notification et devra approuver votre invitation. Pour les événements ouverts, tout membre peut inviter librement ses amis, comme dans les groupes.",

  FaqId8Q:
    "J'ai bloqué quelqu’un sur Join. Peut-il voir les groupes et événements auxquels je participe ?",
  FaqId8A:
    "Les personnes que vous avez bloquées ne peuvent pas voir si vous avez rejoint, créé ou été invité à un groupe ou un événement, sauf si elles y participent également.",

  FaqId9Q: "Que vois-je dans la 'Hot Page' ?",
  FaqId9A:
    "Le contenu de Join est composé de Groupes et d’Événements Utilisateur. Les Groupes peuvent être créés sous **Lieux** ou **Événements Vérifiés**, tandis que les Événements Utilisateur sont des entités indépendantes créées et personnalisées par les utilisateurs. Notre algorithme filtre les groupes et événements personnalisés en fonction de la compatibilité avec votre profil et les affiche dans la Hot Page.",

  FaqId10Q: "Pourquoi Join demande autant d'informations sur mon profil ?",
  FaqId10A:
    "Nous respectons vos préoccupations en matière de confidentialité, mais notre objectif est de vous aider à rencontrer de nouvelles personnes dans la vraie vie. Pour y parvenir, nous avons besoin d’en savoir plus sur vous : ce que vous aimez faire, où vous étudiez ou avez étudié, si vous êtes étudiant, et avec quel type de personnes vous aimez interagir.",

  FaqId11Q:
    "Puis-je omettre certaines informations demandées par Join sur mon profil ?",
  FaqId11A:
    "Nous conseillons à nos utilisateurs de remplir leur profil avec précision afin d’améliorer leur expérience sur Join. Cependant, dans de nombreux cas, vous pouvez sélectionner 'Aucun' ou 'Autre' si vous préférez ne pas partager certaines informations.",

  FaqId12Q: "Qu'est-ce que le 'Score de Profil' ?",
  FaqId12A:
    "Le Score de Profil indique à quel point votre profil est pertinent et solide sur Join, en fonction de son état actuel. Il ne montre pas s’il est complet ou non et il est visible uniquement par vous.",
  FaqId13Q: "Quelles sont ces deux options ?",
  FaqId13A:
   "À cette étape, tu vois deux options.\n\nLa première est de créer ton propre **Event Utilisateur**. Clique sur le bouton -Event Utilisateur- et organise ton propre événement, que ce soit une fête, un événement sportif, une rencontre ou même un voyage !\n\nLa deuxième option te permet de choisir un Magasin ou un Grand-Event pour y créer un **Groupe**. Choisis cette option si l'endroit souhaité est un Magasin ou un Grand-Event déjà répertorié sur Join.",
  FaqId14Q:
    "Quelle est la différence entre un 'Événement Utilisateur' et un 'Groupe' ?",
  FaqId14A:
   "Un **Event Utilisateur** est un événement créé par un autre membre de Join, tout comme toi, contrairement aux **Grands-Events**, qui sont similaires aux **Magasins** et qui sont ajoutés par l'équipe de Join.\n\nLes **Events Utilisateur** ont leur propre entité et visibilité, tandis que les **Groupes** sont listés uniquement sous un Magasin ou un Grand-Event."
};

export default fr;


