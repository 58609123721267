function getFaqs(t, category) {
  if (!t) throw new Error("Translation function `t` is required.");

  const translatedFaqs = faqs.map((faq) => ({
    ...faq,
    question: t(faq.questionKey),
    answer: t(faq.answerKey),
  }));

  return category
    ? translatedFaqs.filter((faq) => faq.categories.includes(category))
    : translatedFaqs;
}

const faqs = [
  {
    id: 1,
    categories: ["groupCreation", "interact"],
    questionKey: "FaqId1Q",
    answerKey: "FaqId1A",
  },
  {
    id: 2,
    categories: ["eventCreation", "interact", "eventsMore"],
    questionKey: "FaqId2Q",
    answerKey: "FaqId2A",
  },
  {
    id: 3,
    categories: ["groupCreation", "groupPage", "interact"],
    questionKey: "FaqId3Q",
    answerKey: "FaqId3A",
  },
  {
    id: 4,
    categories: ["eventCreation", "eventPage", "interact", "eventsMore"],
    questionKey: "FaqId4Q",
    answerKey: "FaqId4A",
  },
  {
    id: 5,
    categories: ["groupCreation", "interact"],
    questionKey: "FaqId5Q",
    answerKey: "FaqId5A",
  },
  {
    id: 6,
    categories: [
      "groupCreation",
      "eventCreation",
      "groupPage",
      "eventPage",
      "interact",
    ],
    questionKey: "FaqId6Q",
    answerKey: "FaqId6A",
  },
  {
    id: 7,
    categories: ["eventPage", "interact", "eventsMore"],
    questionKey: "FaqId7Q",
    answerKey: "FaqId7A",
  },
  {
    id: 8,
    categories: [],
    questionKey: "FaqId8Q",
    answerKey: "FaqId8A",
  },
  {
    id: 9,
    categories: ["interact", "eventsMore"],
    questionKey: "FaqId9Q",
    answerKey: "FaqId9A",
  },
  {
    id: 10,
    categories: ["profile"],
    questionKey: "FaqId10Q",
    answerKey: "FaqId10A",
  },
  {
    id: 11,
    categories: ["profile"],
    questionKey: "FaqId11Q",
    answerKey: "FaqId11A",
  },
  {
    id: 12,
    categories: ["profile"],
    questionKey: "FaqId12Q",
    answerKey: "FaqId12A",
  },
  {
    id: 13,
    categories: ["hotCreate"],
    questionKey: "FaqId13Q",
    answerKey: "FaqId13A",
  },
  {
    id: 14,
    categories: ["hotCreate", "groupPage"],
    questionKey: "FaqId14Q",
    answerKey: "FaqId14A",
  },
];

export { getFaqs };
